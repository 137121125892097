// Dimensions:
$max-width: 1280px;

// Breakpoints:
$phone-large-width: 500px;
$tablet-width: 768px;
$desktop-width: 1300px;

// Colors:
$black: #323232;
$purple: #581DC2;
$myop-blue: #0073FF;

// Spacing:

$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-sm: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 3rem;
$spacing-xl: 4rem;