/* GLOBAL RESET FOR MARGIN AND PADDING */
* {
	box-sizing: border-box;
}

html,
header,
body,
div,
span,
h1,
h2,
h3,
p,
a,
img,
ul,
li,
form,
label,
input {
	margin: 0px;
	padding: 0px;
}
