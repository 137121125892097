@use "../../partials/_variables.scss" as *;
@use "../../partials/mixins" as *;

.edit-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    min-height: calc( 100vh - 4.5rem );
    background: linear-gradient(45deg, #00C5FC, #0041F5);

    &__container{
        @include section-max-width-container;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: $spacing-m;
        @include phone-large{
            padding: 2rem 4rem;
        }
        @include tablet{
            flex-direction: row;
            padding: 4rem;
        }
    }
}
            
                