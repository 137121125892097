@use "../../partials/_variables.scss" as *;
@use "../../partials/mixins" as *;
@use "../../partials/typography" as *;

.editor{
    background-color: #f9f9f9;
    width: 100%;
    border-radius: 0 0 $spacing-m $spacing-m;
    @include phone-large{
        border-radius: 0 0 4rem 4rem;
    }
    overflow: scroll;
    padding: 3rem 1.5rem;
    @include tablet{
        padding: 3rem;
        width: 35%;
        height: calc(100vh - 12.5rem);
        min-height: 450px;
        border-radius: 0 4rem 4rem 0;
    }
    box-shadow: 1rem 0px 1.5rem 0.5rem #DCDCDC inset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__form{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
    }

    &__options-group{
        width: 100%;
        margin-bottom: 1.5rem;
        @include desktop{
            margin-bottom: 2rem;
        }
    }

    &__header-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
    }

    &__save-link{
        cursor: pointer;
        text-decoration: none;
        @include link;
        color: $purple;
        margin-top: $spacing-s;

        &--inactive{
            color: #e1e1e1;
            cursor: none;
        }
    }

    &__text-input{
        width: 100%;
        max-width: 10rem;
        text-overflow: ellipsis;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid $black;
        @include label;
        font-family: "Poppins";
        margin-right: $spacing-s;
    }

    &__heading{
        margin-bottom: 1rem;
        @include subheader;
    }

    &__dimension-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__label-container{
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }

    &__label{
        width: 6rem;
        @include label;
    }

    &__value{
        width: 3rem;
        @include value;
    }

    &__slider{
        -webkit-appearance: none;
        background-color: #f9f9f9;
        width: 100%;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            background: #0073FF;
            height: 2px;
        }

        &::-moz-range-track {
            background: #0073FF;
            height: 2px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 1rem;
            width: 1rem;
            background: white;
            border: 2px solid #581DC2;
            margin-top: -0.5rem;
            border-radius: 50%;
        }

        input[type="range"]::-moz-range-thumb {
            height: 1rem;
            width: 1rem;
            background: white;
            border: 2px solid #581DC2;
            margin-top: -0.5rem;
            border-radius: 50%;
        }
    }

    &__selection-container{
        width: 100%;
        max-width: 10rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        &:last-of-type{
            margin-bottom: 0;
        }
    }

}