@use "../../partials/typography" as *;
@use "../../partials/variables" as *;
@use "../../partials/mixins" as *;

.preview-pattern {
  width: 100%;
  height: 60vh;
  @include tablet {
    height: 100%;
  }
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__svg-container {
    width: 100%;
    height: 200px;
    overflow: scroll;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__svg-label {
    font: 35px arial;
  }

  &__footer {
    background-color: white;
    margin-top: 1rem;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__download-option {
    cursor: pointer;
    text-decoration: none;
    @include link;
    color: $purple;
  }
}
