@use "../../partials/_variables.scss" as *;
@use "../../partials/mixins" as *;
@use "../../partials/typography" as *;

.site-header{
    background-color: white;

    &__container{
        @include section-max-width-container;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }

    &__title{
        @include page-title;
        color: #0073FF;
    }

    &__link{
        text-decoration: none;
        @include page-title;
        color: #0073FF;
    }

    &__logout{
        cursor: pointer;
        text-decoration: none;
        @include link;
        color: $purple;
    }

}
            
                