@use '../partials/variables' as *;


// Import Fonts
@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
		url("../assets/fonts/Poppins-Light.woff") format("woff"),
		url("../assets/fonts/Poppins-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
		url("../assets/fonts/Poppins-Regular.woff") format("woff"),
		url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
		url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
		url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
		url("../assets/fonts/Poppins-Bold.woff") format("woff"),
		url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

body {
	font-family: "Poppins";
}

// Typography

@mixin page-title{
    font-size: 1.5rem;
	line-height: 2.5rem;
	font-weight: 700;
}

@mixin header {
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-weight: 600;
	color: $black;
}

@mixin subheader {
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 600;
	color: $black;
}

@mixin label {
	font-size: 0.8rem;
	line-height: 1.25rem;
	font-weight: 400;
	color: $black;
}

@mixin value {
	font-size: 0.8rem;
	line-height: 1.25rem;
	font-weight: 600;
	color: $black;
}

@mixin body {
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 400;
	color: $black;
}

@mixin link {
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	color: $black;
}