@use "../../partials/_variables.scss" as *;
@use "../../partials/mixins" as *;
@use '../../partials/typography' as *;

.editor-preview{
    width: 100%;
    border-radius: $spacing-m $spacing-m 0 0;
    @include phone-large{
        border-radius: 4rem 4rem 0 0 ;
    }
    @include tablet{
        width: 65%;
        height: calc(100vh - 12.5rem);
        min-height: 450px;
        border-radius: 4rem 0 0 4rem;
    }
    background-color: #f8f8ff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: none;

    &__header{
        background-color: white;
        padding-top: $spacing-sm;
        @include tablet{
            padding-top: $spacing-m;
        }
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 1rem;
        @include header;
    }

    &__tab{
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.5rem;

        &--active{
            border-bottom: 2px solid #0073FF;
        }
    }

    &__tab-title{
        font-weight: 600;
        cursor: pointer;
    }
}