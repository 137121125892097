@use "../../partials/typography" as *;
@use "../../partials/variables" as *;
@use "../../partials/mixins" as *;

.preview-model{
    overflow: none;
    width: 100%;
    height: 60vh;
    @include tablet{
        height: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__canvas-container{
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 50px;
    }

    &__footer{
        background-color: white;
        margin-top: 1rem;
        width: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__view-option{
        cursor: pointer;
        text-decoration: none;
        @include link;
        color: $purple;
    }
}